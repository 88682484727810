import { Rating } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { styled } from '@mui/material/styles';

interface Props {
  totalReview?: number;
  avgReview?: number;
  size?: number;
  alwayShowSpace?: boolean;
  isShowZero?: boolean;
}

const StyledRating = styled(Rating)({
  marginTop: '2px',
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export default function ReviewProduct({
  totalReview,
  avgReview,
  size,
  alwayShowSpace,
  isShowZero,
}: Props) {
  return avgReview || isShowZero ? (
    <Box>
      <StyledRating
        value={avgReview}
        readOnly
        icon={
          <i
            className={`bi bi-star-fill text-secondaryOrange mr-0.5`}
            style={{
              fontSize: size ? `${size}rem` : '0.5rem',
              lineHeight: size ? `${size + size / 2}rem` : '0.75rem',
            }}
          />
        }
        emptyIcon={
          <i
            className={`bi bi-star text-secondaryOrange mr-0.5`}
            style={{
              fontSize: size ? `${size}rem` : '0.5rem',
              lineHeight: size ? `${size + size / 2}rem` : '0.75rem',
            }}
          />
        }
      />
      {totalReview ? (
        <span
          className="text-grayDark"
          style={{
            fontSize: size ? `${size + size / 2}rem` : '0.75rem',
            lineHeight: size ? `${size + size / 2}rem` : '0.75rem',
          }}
        >
          ({totalReview})
        </span>
      ) : (
        <></>
      )}
    </Box>
  ) : alwayShowSpace ? (
    <Box height={size ? `${size + size / 2}rem` : '0.75rem'}></Box>
  ) : null;
}
