import {
  clearError,
  errorCodeSelector,
  wishListAdd,
  wishListRemove,
  wishListResultsSelector,
} from '@/store/slices/wishListSlice';
import { useAppDispatch } from '@/store/store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as showDialog from '@/utils/showDialog';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getRedirectQuery } from '@/utils/theone';
import { ProductModel } from '@/models/Product.model';
import { customerIdSelector } from '@/store/slices/loginSlice';
import { currencyCode } from '@/constants/defaultValue.constant';

interface Props {
  product: ProductModel;
  size?: number;
  text?: boolean;
}

export default function WishListProduct({ product, size, text }: Props) {
  const dispatch = useAppDispatch();
  const errorCode = useSelector(errorCodeSelector);
  const wishListResults = useSelector(wishListResultsSelector);
  const { t } = useTranslation('');
  const router = useRouter();
  const active = wishListResults.find((item) => item === product.sku) != null;
  const customerId = useSelector(customerIdSelector);

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case '401':
          showDialog
            .showMessageWithButton({
              text: t('pleaseloginbeforeadd'),
              primaryButton: {
                name: t('signin'),
                callBack: () => {
                  router.push(
                    `/${router.locale}/signin${getRedirectQuery(
                      router.asPath,
                    )}`,
                  );
                },
              },
              secondaryButton: {
                name: t('cancel'),
              },
            })
            .then(() => {
              dispatch(clearError());
            });
          break;
        default:
          showDialog
            .showErrorWithOkButton({
              text: t('thesystemistemporarilypleasetryagainlater'),
            })
            .then(() => {
              dispatch(clearError());
            });
          break;
      }
    }
  }, [errorCode, t, dispatch, router]);

  const wlistListClick = (e: any) => {
    e.preventDefault();
    if (active) {
      dispatch(wishListRemove({ sku: product.sku }));
    } else {
      dispatch(wishListAdd({ sku: product.sku }));
      try {
        if ((window as any).dataLayer && customerId !== undefined) {
          (window as any).dataLayer.push({ ecommerce: null });
          (window as any).dataLayer.push({
            event: 'addToWishList',
            userId: customerId,
            ecommerce: {
              currency: currencyCode,
              value: product.price ? product.price.replace(/,/g, '') : '0',
              products: [
                {
                  name: product.name,
                  id: product.sku,
                  price: product.price ? product.price.replace(/,/g, '') : '0',
                  brand: product.brand ?? '',
                  category: product.catName ?? '',
                  quantity: 1,
                },
              ],
            },
          });
        }
      } catch (_) {}
    }
  };

  return (
    <div
      className="flex items-center cursor-pointer"
      style={{
        height: size ? `${size + size / 2}rem` : '0.75rem',
      }}
      onClick={wlistListClick}
    >
      <i
        className={
          active
            ? 'bi bi-heart-fill text-redPrice'
            : 'bi bi-heart text-grayDark hover:text-redPrice'
        }
        style={{
          marginTop: (size ?? 0.75) * 3,
          fontSize: size ? `${size}rem` : '0.75rem',
          lineHeight: size ? `${size}rem` : '0.75rem',
          height: size ? `${size}rem` : '0.75rem',
        }}
      />
      {text && (
        <div
          style={{
            fontSize: size ? `${size}rem` : '0.75rem',
            lineHeight: size
              ? `${(size ?? 0.75) + (size ?? 0.75) / 2}rem`
              : '0.75rem',
            height: size
              ? `${(size ?? 0.75) + (size ?? 0.75) / 2}rem`
              : '0.75rem',
          }}
          className="ms-1 text-grayDark overflow-hidden"
        >
          {t('wishlist')}
        </div>
      )}
    </div>
  );
}
