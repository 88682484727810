import React from 'react';

interface Props {
  value?: boolean;
  size?: number;
  color?: string;
}

export default function CheckBoxCompare({ value, size, color }: Props) {
  return (
    <div
      className={`relative rounded-sm border ${
        color ? `border-${color}` : 'border-grayDark'
      } flex`}
      style={{
        width: size ? `${size}rem` : '0.75rem',
        height: size ? `${size}rem` : '0.75rem',
      }}
    >
      {value && (
        <i
          className={`bi bi-check-lg self-center mt-0.5  ${
            color ? `text-${color}` : 'text-primary'
          }`}
          style={{
            marginLeft: -1,
            lineHeight: size ? `${size}rem` : '0.75rem',
            fontSize: size ? `${size}rem` : '0.75rem',
          }}
        />
      )}
    </div>
  );
}
