// import { useTranslation } from 'next-i18next';
// import React, { useState } from 'react';
// import Image from 'next/image';
// import Tooltip from '@mui/material/Tooltip';
// import Grid from '@mui/material/Grid';

// export default function FreeInstallmentBadge() {
//   const { t } = useTranslation('productcard');
//   const [showTooltip, setShowTooltip] = useState<boolean>(false);
//   const installmentList: string[] = [
//     't1_credit',
//     't1_firstchoice',
//     'scb',
//     'ttb',
//     'uob',
//     'ktc',
//     'kbank',
//     'krungsri',
//     'firstchoice',
//     'bbl',
//     'citi',
//   ];
//   return (
//     <Tooltip
//       open={showTooltip}
//       onOpen={() => setShowTooltip(true)}
//       onClose={() => setShowTooltip(false)}
//       leaveTouchDelay={0}
//       title={
//         <div
//           onClick={(e) => {
//             e.preventDefault();
//           }}
//           className="pb-1 w-24 px-1 rounded-sm bg-white"
//           style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 5px' }}
//         >
//           <div>{t('installmentplans')}</div>
//           <Grid container spacing={0.25} className="h-14 overflow-y-scroll">
//             {installmentList.map((item, i) => (
//               <Grid key={i} item xs={6}>
//                 <Image
//                   src={`/images/icons/installment/${item}.png`}
//                   alt={item}
//                   width={102}
//                   height={45}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </div>
//       }
//       placement="top"
//       arrow
//       componentsProps={{
//         tooltip: {
//           className: 'tooltip-arrow',
//           sx: { backgroundColor: 'white', color: 'black', width: '100%', p: 0 },
//         },
//       }}
//     >
//       <div
//         onClick={(e) => {
//           setShowTooltip(!showTooltip);
//           e.preventDefault();
//         }}
//         className="border-info border text-xs text-info h-3.5 text-center text-ellipsis overflow-hidden px-2"
//         style={{ lineHeight: '0.875rem' }}
//       >
//         {t('zeropercentinstallment')}
//       </div>
//     </Tooltip>
//   );
// }
// import { useTranslation } from 'next-i18next';
import React from 'react';
import { useRouter } from 'next/router';

interface Props {
  installment_text?: string;
  installment_link?: string;
}
export default function FreeInstallmentBadge({
  installment_text,
  installment_link,
}: Props) {
  // const { t } = useTranslation('productcard');
  const router = useRouter();

  return installment_text && installment_text !== '' ? (
    <div
      onClick={(e) => {
        e.preventDefault();
        if (installment_link && installment_link !== '') {
          router.push(`${installment_link}`);
        }
      }}
      className="border-info border text-xs text-info h-3.5 text-center text-ellipsis overflow-hidden px-2"
      style={{ lineHeight: '0.875rem' }}
    >
      <span>{installment_text}</span>
    </div>
  ) : (
    <></>
  );
}
