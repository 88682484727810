import { ProductModel } from '@/models/Product.model';
import React from 'react';
import FreeInstallmentBadge from '../badge/FreeInstallmentBadge';
import CreditCardOnTopBadge from '../badge/CreditCardOnTopBadge';
import FreeInstallationBadge from '../badge/FreeInstallationBadge';
import FreeItemBadge from '../badge/FreeItemBadge';

interface Props {
  product: ProductModel;
}

export default function ProductCardBadgeLayout({ product }: Props) {
  const freeInstallBadge = product?.freeInstallBadge;
  let jsnLink = product?.jsnLink;
  let installment_text: string = '';
  let installment_link: string = '';
  let creditcardontop_text: string = '';
  let creditcardontop_link: string = '';
  if (jsnLink) {
    try {
      const jsnLinkJson = JSON.parse(jsnLink);
      if (jsnLinkJson && jsnLinkJson.length > 0) {
        const installments = jsnLinkJson.filter(
          (item: any) => item.GROUPCAT === 'INSTALLMENT',
        );
        const creditcardontops = jsnLinkJson.filter(
          (item: any) => item.GROUPCAT === 'CREDITCARDONTOP',
        );
        if (installments && installments.length > 0) {
          installment_text = installments[0].Text ? installments[0].Text : '';
          installment_link = installments[0].Link ? installments[0].Link : '';
        }
        if (creditcardontops && creditcardontops.length > 0) {
          creditcardontop_text = creditcardontops[0].Text
            ? creditcardontops[0].Text
            : '';
          creditcardontop_link = creditcardontops[0].Link
            ? creditcardontops[0].Link
            : '';
        }
      }
    } catch (error) {}
  }

  return (
    <div className="grid grid-cols-2 gap-1 mb-1">
      {installment_text && installment_text !== '' ? (
        <div>
          <FreeInstallmentBadge
            installment_text={installment_text}
            installment_link={installment_link}
          />
        </div>
      ) : (
        <></>
      )}
      {creditcardontop_text && creditcardontop_text !== '' ? (
        <div>
          <CreditCardOnTopBadge
            creditcardontop_text={creditcardontop_text}
            creditcardontop_link={creditcardontop_link}
          />
        </div>
      ) : (
        <></>
      )}
      {/* {product.freeInstallBadge == 1 && (
              <Grid item xs={12} sm={6}>
                <div className="border-info border text-sm text-info h-4 leading-4 text-center">
                  {t('freeinstallation')}
                </div>
              </Grid>
            )} */}
      {freeInstallBadge ? (
        <div>
          <FreeInstallationBadge />
        </div>
      ) : (
        <></>
      )}
      {(product.freegiftMessage || product.freegiftBundle) && (
        <div>
          <FreeItemBadge sku={product.sku} />
        </div>
      )}
    </div>
  );
}
