import {
  GetPDPFreeGiftRootModel,
  GetPDPFreeGiftModel,
  GetPDPFreeGiftModelConverter,
} from '@/models/GetPdpFreeGift.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const getPdpFreeGiftData = async ({
  lang,
  skcode,
}: {
  lang: string;
  skcode?: string;
}): Promise<GetPDPFreeGiftRootModel> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/getpdpfreegift',
      lang,
      body: { lang, skcode },
    }),
  ]);
  const apiData =
    getApiData.status === 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;

  let response: GetPDPFreeGiftRootModel = [];
  if (apiData && apiData.length > 0) {
    try {
      const convertedArray: GetPDPFreeGiftModel[] = [];
      apiData.forEach((ele: any) => {
        const convertedElement = GetPDPFreeGiftModelConverter.fromJson(ele);
        convertedArray.push(convertedElement);
      });
      response = convertedArray;
    } catch (error) {
      console.log(error); // Log the error for debugging purposes
      return response; // Return the response array even in case of an error
    }
  }
  return response;
};
