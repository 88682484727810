import { ProductModel } from '@/models/Product.model';
import React from 'react';
import SaleBadge from '../badge/SaleBadge';
import AtbBadge from '../badge/AtbBadge';
import PromotionBadge from '../badge/PromotionBadge';
import OutOfStockBadge from '../badge/OutOfStockBadge';

interface Props {
  product: ProductModel;
}

export default function ProductCardImageBadgeLayout({ product }: Props) {
  const atbList = (product.atbBadges ?? []).map((e) => e.base64 ?? '');
  let jsnLink = product?.jsnLink;
  let promotion_text: string = '';
  let promotion_link: string = '';
  if (jsnLink) {
    try {
      const jsnLinkJson = JSON.parse(jsnLink);
      if (jsnLinkJson && jsnLinkJson.length > 0) {
        const promotions = jsnLinkJson.filter(
          (item: any) => item.GROUPCAT === 'PROMOTION',
        );
        if (promotions && promotions.length > 0) {
          promotion_text = promotions[0].Text ? promotions[0].Text : '';
          promotion_link = promotions[0].Link ? promotions[0].Link : '';
        }
      }
    } catch (error) {}
  }

  return (
    <>
      <div className="absolute top-0 right-0">
        <SaleBadge discountPercent={product.discountPercent} />
      </div>
      <div className="absolute bottom-0 left-0">
        <AtbBadge list={atbList} />
      </div>
      {/* <div className="absolute bottom-0 right-0 w-1/2">
      <EventBadge />
    </div> */}
      {promotion_text && promotion_text !== '' && (
        <div className="absolute top-0 left-0 w-full max-w-[65%]">
          <PromotionBadge
            promotion_text={`${promotion_text}`}
            promotion_link={promotion_link}
          />
        </div>
      )}
      {(product.stockAvail ?? 0) <= 0 && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <OutOfStockBadge />
        </div>
      )}
    </>
  );
}
