import { AddToCartRequest, SkuWithQty } from '@/models/AddToCart.model';
import {
  addToCart,
  addingToCartSelector,
  addingToCartSkuSelector,
} from '@/store/slices/addToCartSlice';
import { useAppDispatch } from '@/store/store';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../common/Spinner';
import { mapSkuAddingToCart } from '@/utils/format';
import aa from 'search-insights';

interface Props {
  sku?: string;
  isLineButton?: boolean;
  atbFlag?: string;
  xsShort?: boolean;
  qty?: number;
  isNoShort?: boolean;
  withService?: string;
  //bundle
  skuQtyList?: SkuWithQty[];
  setId?: string;
  isSearch?: boolean;
  aglqueryid?: string;
  price?: string;
  discount?: string;
}

export default function AddToCartButton({
  sku,
  atbFlag,
  xsShort,
  qty,
  isNoShort,
  withService,
  skuQtyList,
  setId,
  isSearch,
  aglqueryid,
  price,
  discount,
}: Props) {
  const { t, i18n } = useTranslation('productcard');
  const dispatch = useAppDispatch();
  const addingToCart = useSelector(addingToCartSelector);
  const addingToCartSku = useSelector(addingToCartSkuSelector);
  const isLoading: boolean =
    addingToCartSku ===
      mapSkuAddingToCart({
        sku,
        setId,
        skuQtyList,
      }) && addingToCart;
  const handleAddtocart = (e: any) => {
    e.preventDefault();
    if (!isLoading) {
      if (sku) {
        const addToCartData: AddToCartRequest = {
          sku: sku,
          qty: qty ?? 1,
          withService: withService,
          setId: setId,
          lang: i18n.language,
          aglqueryid: aglqueryid,
        };
        dispatch(addToCart(addToCartData));
        if (isSearch && aglqueryid) {
          aa('getUserToken', {}, (err, userToken) => {
            if (err) {
              console.error(err);
              return;
            }
            aa('init', {
              appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
              apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
              partial: true,
              useCookie: true,
              userToken: `${userToken}`,
              authenticatedUserToken: `${userToken}`,
            });

            let index_algolia = '';
            if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
              index_algolia = `prod_${i18n.language}_products`;
            } else {
              index_algolia = `uat_${i18n.language}_products`;
            }
            aa('addedToCartObjectIDsAfterSearch', {
              eventName: 'Product Added To Cart',
              index: index_algolia,
              objectIDs: [sku],
              queryID: aglqueryid,
              objectData: [
                {
                  queryID: aglqueryid,
                  price: Number(price),
                  discount: Number(discount),
                  quantity: qty ?? 1,
                },
              ],
              currency: 'THB',
            });
          });
        }
      } else if (skuQtyList && skuQtyList.length > 0) {
        const addToCartData: AddToCartRequest = {
          sku: '',
          skuQtyList: skuQtyList,
          qty: qty ?? 1,
          withService: withService,
          setId: setId,
          lang: i18n.language,
        };
        dispatch(addToCart(addToCartData));
      }
    }
  };
  //ปรับเป็น false ไปเพราะว่า pwb ไม่มี chat and shop
  return false ? (
    <div
      onClick={(e: any) => {
        e.preventDefault();
        // open net tab tarbet blank
        window.open(
          `${
            ['V', 'XV'].includes(atbFlag ?? '')
              ? 'https://line.me/R/ti/p/@powerbuy'
              : 'https://line.me/R/ti/p/@powerbuy'
          }`,
        );
      }}
    >
      <Button
        className="text-white text-xs bg-[#00B901] hover:bg-[#45ac45] h-6 leading-3 w-full sm:w-auto min-w-0"
        sx={{
          backgroundColor: '#00B901',
          color: 'white',
          '&:hover': {
            backgroundColor: '#45ac45',
          },
          height: '1.75rem',
          fontSize: '0.75rem',
          lineHeight: '0.75rem',
          minWidth: '0',
          width: '100%',
          sm: {
            width: 'auto',
          },
        }}
        aria-label="chat and shop"
      >
        <i className="bi bi-line text-xs h-3"></i>
        <div
          className={`overflow-hidden ms-1 sm:lg-1 w-full line-clamp-2 addtocart-text${
            xsShort ? '-xs' : ''
          }`}
        >
          Chat & Shop
        </div>
      </Button>
    </div>
  ) : (
    <Button
      disabled={
        (sku === null || sku === undefined || sku === '') &&
        (setId == '' || setId === null || setId === undefined)
      }
      onClick={handleAddtocart}
      className={`text-white text-xs bg-secondaryPurple hover:bg-primary h-6 leading-3 w-full ${
        isNoShort ? '' : 'sm:w-auto'
      } min-w-0`}
      sx={{
        backgroundColor: '#854BA2',
        color: 'white',
        '&:hover': {
          backgroundColor: '#4E1F66',
        },
        '&:disabled': {
          backgroundColor: '#EBEBEB',
        },
        height: '1.75rem',
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        minWidth: '0',
        width: '100%',
        sm: {
          width: 'auto',
        },
      }}
      aria-label="add to cart"
    >
      {isLoading ? (
        <Spinner size={18} borderSize={2} />
      ) : (
        <div className="flex">
          {isNoShort ? (
            <></>
          ) : (
            <div className={`addtocart-plus${xsShort ? '-xs' : ''}`}>+</div>
          )}
          <i className="bi bi-cart text-xs h-3"></i>
        </div>
      )}
      <div
        className={`overflow-hidden ms-1 sm:lg-1 w-full line-clamp-2 ${
          isNoShort ? '' : `addtocart-text${xsShort ? '-xs' : ''}`
        } `}
      >
        {t('addtocart')}
      </div>
    </Button>
  );
}
