import Box from '@mui/material/Box';
import React from 'react';
import Image from 'next/image';

interface Props {
  discountPercent?: number;
}

export default function SaleBadge({ discountPercent }: Props) {
  return discountPercent ? (
    <Box className="relative" width={63} height={30}>
      <Image src="/images/svg/plp/sale.svg" alt="sale" width={63} height={30} />
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/3 -translate-y-1/2 text-white font-bold text-base">
        {discountPercent}%
      </Box>
    </Box>
  ) : null;
}
