import React from 'react';
import ProductImageBase64 from '@/components/common/ProductImageBase64';

interface Props {
  list?: string[];
  classNameOverride?: string;
}

export default function AtbBadge({ list, classNameOverride }: Props) {
  return (
    <>
      {list?.map((e, i) => (
        <div
          key={i}
          className={`relative mt-0.5 ${classNameOverride ?? 'w-5 sm:w-6'}`}
        >
          <ProductImageBase64 base64={e} />
        </div>
      ))}
    </>
  );
}
