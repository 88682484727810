import React, { useEffect, useRef } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { ProductModel } from '@/models/Product.model';
import ProductImageBase64 from '../common/ProductImageBase64';
import { useRouter } from 'next/router';
import ReviewProduct from './ReviewProduct';
import WishListProduct from './WishListProduct';
import CompareProduct from './CompareProduct';
import AddToCartButton from './AddToCartButton';
import { useSelector } from 'react-redux';
import { customerIdSelector } from '@/store/slices/loginSlice';
import AppLink from '../Layout/handler/AppLink';
import { useAppDispatch } from '@/store/store';
import { addProductToList } from '@/store/slices/productViewSendSlice';
import ProductCardImageBadgeLayout from './badgeLayout/ProductCardImageBadgeLayout';
import ProductCardBadgeLayout from './badgeLayout/ProductCardBadgeLayout';
import aa from 'search-insights';

export enum CardType {
  // eslint-disable-next-line no-unused-vars
  normal = 1,
  // eslint-disable-next-line no-unused-vars
  slide = 2,
  // eslint-disable-next-line no-unused-vars
  spu = 3,
}

interface Props {
  position?: number;
  listName?: string;
  product: ProductModel;
  hideBottomAction?: boolean;
  type?: CardType;
  isSearch?: boolean;
  aglqueryid?: string;
  algposition?: number;
}

export default function ProductCard({
  position,
  listName,
  product,
  hideBottomAction,
  type,
  isSearch,
  aglqueryid,
  algposition,
}: Props) {
  type = type ?? CardType.normal;
  if (type === CardType.spu && (product.spu === 0 || product.skuCount === 1)) {
    type = CardType.normal;
  }
  const router = useRouter();
  const { t, i18n } = useTranslation('productcard');
  const customerId = useSelector(customerIdSelector);
  const productRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const productSend = productRef.current;
    if (!productSend || !(window as any).dataLayer || customerId === undefined)
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        dispatch(addProductToList({ ...product, position, listName }));
        observer.disconnect();
      }
    });

    observer.observe(productSend);

    return () => {
      if (productSend && observer) {
        observer.unobserve(productSend);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const handleProductClick = () => {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({ ecommerce: null });
      (window as any).dataLayer.push({
        event: 'productClick',
        userId: customerId,
        ecommerce: {
          list: listName, // Optional list property.
          products: [
            {
              name: product.name,
              id: product.sku,
              price: product.price ? product.price.replace(/,/g, '') : '0',
              brand: product.brand ?? '',
              position: position,
              category: product.catName ?? '',
              quantity: 1,
              list: listName ?? '',
            },
          ],
        },
      });
    }
    if (isSearch && algposition) {
      aa('getUserToken', {}, (err, userToken) => {
        if (err) {
          console.error(err);
          return;
        }
        aa('init', {
          appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
          apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
          partial: true,
          useCookie: true,
          userToken: `${userToken}`,
          authenticatedUserToken: `${userToken}`,
        });
        let index_algolia = '';
        if (process.env.NEXT_PUBLIC_NODE_ENV == 'production') {
          index_algolia = `prod_${i18n.language}_products`;
        } else {
          index_algolia = `uat_${i18n.language}_products`;
        }
        aa('clickedObjectIDsAfterSearch', {
          index: index_algolia,
          queryID: aglqueryid ? aglqueryid : '',
          eventName: 'Product Clicked',
          objectIDs: [product.sku],
          positions: [algposition ? algposition : 1],
        });
      });
    }
  };
  return (
    <div className="px-1 md:px-4 pt-1 md:pt-2 pb-2 bg-white hover:shadow-md cursor-pointer h-full flex flex-col">
      <AppLink
        href={`/product/${product.slugname}${
          aglqueryid ? `?queryID=${aglqueryid}` : ''
        }`}
        onClick={handleProductClick}
        className="flex flex-col flex-grow"
      >
        <div ref={productRef} className="flex flex-col flex-grow">
          <div className="relative">
            <div id={`product-image-${product.sku}`} className="p-xs-0 p-lg-5">
              <div className="max-w-[319px] max-h-[319px]">
                <ProductImageBase64
                  pimImage={product.pimImage}
                  base64={product.base64}
                  alt={`${product?.brand?.trim()} ${product.name}`}
                  className="h-[calc(50vw-1.25rem)] sm:h-[calc(33.33vw-1.25rem)] md:h-[calc(25vw-2.5rem)] lg:h-[calc(20vw-2.5rem)] xl:h-[calc(272px-2.25rem)]"
                />
              </div>
            </div>
            <ProductCardImageBadgeLayout product={product} />
          </div>
          <ProductCardBadgeLayout product={product} />
          <h2 className="leading-3 h-9 overflow-hidden mb-0.5 mt-auto">
            <span
              onClick={(e) => {
                e.preventDefault();
                router.push(`/${router.locale}/brand/${product.brand}`);
              }}
              className="font-bold text-xs leading-3 hover:underline"
            >
              {product.brand}
            </span>
            <span className="text-xs leading-3 mx-1 hover:underline">
              {product.name}
            </span>
          </h2>
          <div className="h-[1.1rem] flex flex-col">
            {type !== CardType.spu ? (
              <ReviewProduct
                totalReview={product.totalReview}
                avgReview={product.avgReview}
                size={0.6}
                alwayShowSpace={true}
              />
            ) : (
              <div className="leading-3 text-xs mt-auto">
                {t('common:start')}
                <span className="text-redPrice text-sm font-bold pl-1">{`฿${
                  product.minPrice
                    ? parseFloat(
                        product.minPrice.replace(/,/g, ''),
                      ).toLocaleString()
                    : ''
                }`}</span>
              </div>
            )}
          </div>
          <div className="text-redPrice font-bold text-sm leading-3 w-full flex">
            {type !== CardType.spu ? (
              `฿${product.price}`
            ) : (
              // <div className="my-auto">{`฿${product.minPrice} - ${product.maxPrice}`}</div>
              <></>
            )}
          </div>
          {product.discount && type !== CardType.spu ? (
            <div className="flex">
              <div className="text-xs leading-3 line-through">{`฿${product.discount}`}</div>
              <div className="text-xs leading-3 ms-2">{`${t('common:save')} ฿${
                product.priceSaved
              }`}</div>
            </div>
          ) : (
            <div className="h-3"></div>
          )}
        </div>
        <div className="h-7 md:h-3.5 select-none">
          {type !== CardType.spu && (
            <>
              <Grid container columnSpacing={1} rowSpacing={0.25}>
                {product.canPickup === true && (
                  <Grid item xs={12} md={6}>
                    <div className="bg-secondaryOrange text-xxs text-white leading-3 text-center rounded-md line-clamp-1">
                      {t('pickup1h')}
                    </div>
                  </Grid>
                )}
                {product.canDelivery === true && (
                  <Grid item xs={12} md={6}>
                    <div className="bg-primary text-xxs text-white leading-3 text-center rounded-md">
                      {t('delivery')}
                    </div>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </div>
      </AppLink>
      {hideBottomAction !== true ? (
        <div className="block sm:flex items-center">
          {type !== CardType.spu && (
            <div className="flex items-center justify-around xs:pb-0.5 sm:pb-0">
              <WishListProduct product={product} />
              <div className="ms-1" style={{ marginTop: 2 }}>
                <CompareProduct
                  sku={product.sku}
                  pimImage={product.pimImage}
                  slugname={product.slugname}
                />
              </div>
            </div>
          )}
          <div className="ms-auto ps-0.5 h-6">
            {type !== CardType.spu ? (
              (product.stockAvail ?? 0) > 0 ? (
                <AddToCartButton
                  sku={product.sku}
                  isLineButton={product.isLineButton}
                  atbFlag={product.atbFlag}
                  withService={
                    product.forceINST &&
                    product.forceINST == 1 &&
                    product.serSKCODE &&
                    product.serSKCODE !== ''
                      ? product.serSKCODE
                      : undefined
                  }
                  isSearch={isSearch}
                  aglqueryid={aglqueryid}
                  price={product.price}
                  discount={product.discount}
                />
              ) : (
                <Button
                  disabled={true}
                  className={`text-xs bg-[#EBEBEB] disabled:bg-[#EBEBEB] h-6 leading-3 w-full sm:w-auto min-w-0`}
                  sx={{
                    backgroundColor: '#EBEBEB',
                    '&:disabled': {
                      backgroundColor: '#EBEBEB',
                    },
                    height: '1.75rem',
                    fontSize: '0.75rem',
                    lineHeight: '0.75rem',
                    minWidth: '0',
                    width: '100%',
                    sm: {
                      width: 'auto',
                    },
                  }}
                  aria-label="out of stock"
                >
                  <div className={`overflow-hidden w-full line-clamp-2`}>
                    {t('common:outofstock')}
                  </div>
                </Button>
              )
            ) : (
              <AppLink
                href={`/product/${product.slugname}`}
                onClick={handleProductClick}
                className="flex flex-col flex-grow"
              >
                <div className="text-xs cursor-pointer hover:underline line-clamp-1">{`${t(
                  'common:seeall',
                )} ${product.skuCount ?? 1} ${
                  (product.skuCount ?? 1) > 1
                    ? t('productlist:items')
                    : t('productlist:items').replace('items', 'item')
                }`}</div>
              </AppLink>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
