import React from 'react';
import styles from '../../../styles/imageProduct.module.css';
import { useRouter } from 'next/router';

interface Props {
  promotion_text?: string;
  promotion_link?: string;
}

export default function PromotionBadge({
  promotion_text,
  promotion_link,
}: Props) {
  const router = useRouter();
  return promotion_text && promotion_text !== '' ? (
    <div
      className={`${styles.promotion} text-xxs p-1`}
      onClick={(e) => {
        e.preventDefault();
        if (promotion_link) {
          router.push(`${promotion_link}`);
        }
      }}
    >
      {promotion_link && promotion_link !== '' ? (
        <span style={{ cursor: 'pointer' }}>{promotion_text}</span>
      ) : (
        <span>{promotion_text}</span>
      )}
    </div>
  ) : (
    <></>
  );
}
