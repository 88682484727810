import { useTranslation } from 'next-i18next';
import React from 'react';

export default function OutOfStockBadge() {
  const { t } = useTranslation('productcard');
  return (
    <div
      className="text-xs text-center text-white rounded-xl bg-black bg-opacity-40 px-1.5 py-0.5"
      style={{
        maxLines: 1,
      }}
    >
      <span>{t('outofstock_short')}</span>
    </div>
  );
}
