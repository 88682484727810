import { useTranslation as translation } from 'next-i18next';
import React from 'react';

export default function FreeInstallmentBadge() {
  const { t } = translation();

  return (
    <>
      <div
        className="border-info border text-xs text-info h-3.5 text-center text-ellipsis overflow-hidden px-2"
        style={{ lineHeight: '0.875rem', zIndex: 9, position: 'relative' }}
      >
        {t('freeinstallation')}
      </div>
    </>
  );
}
