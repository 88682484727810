import { modelValue } from '@/utils/modelValue';
export type GetPDPFreeGiftRootModel = GetPDPFreeGiftModel[];

export interface GetPDPFreeGiftModel {
  protype?: number;
  buyMsg?: string;
  limitMsg?: string;
  skcode?: number;
  quant?: number;
  base64?: string;
  prName?: string;
  slugname?: string;
  stockAvail?: number;
  hb?: string;
  usestock?: string;
  region?: string;
}
export class GetPDPFreeGiftModelConverter {
  public static fromJson(json: any): GetPDPFreeGiftModel {
    const model: GetPDPFreeGiftModel = {
      protype: json['PROTYPE'],
      buyMsg: json['BuyMsg'],
      limitMsg: json['LimitMsg'],
      skcode: json['SKCODE'],
      quant: json['QUANT'],
      base64: json['Base64'],
      prName: json['PR_NAME'],
      slugname: json['slugname'],
      stockAvail: json['StockAvail'],
      hb: json['HB'],
      usestock: json['USESTOCK'],
      region: json['region'],
    };
    return modelValue(model);
  }
}
