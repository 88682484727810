import { Tooltip, Box } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Spinner from '@/components/common/Spinner';
import { GetPDPFreeGiftModel } from '@/models/GetPdpFreeGift.model';
import { getPdpFreeGiftData } from '@/services/client/getPdpFreeGiftService';

interface Props {
  sku: string;
}

export default function FreeItemBadge({ sku }: Props) {
  const { t, i18n } = useTranslation('productcard');
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [freeGifts, setFreeGifts] = useState<
    GetPDPFreeGiftModel[] | undefined | null
  >(undefined);

  useEffect(() => {
    if (showTooltip && freeGifts === undefined) {
      setIsLoading(true);
      getPdpFreeGiftData({
        lang: i18n.language,
        skcode: sku,
      })
        .then((res) => {
          setFreeGifts(res);
          setIsLoading(false);
        })
        .catch(() => {
          setFreeGifts(null);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip]);

  return (
    <Tooltip
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      leaveTouchDelay={0}
      title={
        <div
          onClick={(e) => {
            e.preventDefault();
          }}
          className="pb-1 w-20 px-1 rounded-sm bg-white"
          style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 5px' }}
        >
          <div className="flex justify-center items-center pt-1 min-h-[4.5rem]">
            {isLoading ? (
              <Spinner color="black" borderSize={2} />
            ) : freeGifts && freeGifts.length > 0 ? (
              <div className="flex flex-col items-center space-y-0.5">
                <div className="m-0 text-xs text-secondaryOrange">
                  {`${freeGifts[0].buyMsg} ${t('common:free')}`}
                </div>
                <div style={{ position: 'relative' }}>
                  <Image
                    src={`data:image/jpeg;base64,${freeGifts[0].base64}`}
                    alt={freeGifts[0].prName ?? ''}
                    width={50}
                    height={50}
                    style={{ margin: 'auto' }}
                  />
                </div>
                <div className="text-secondaryPurple">
                  x{freeGifts[0].quant}
                </div>
              </div>
            ) : (
              <>No Data.</>
            )}
          </div>
        </div>
      }
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          className: 'bg-white text-black py-0 w-full tooltip-arrow px-0',
        },
      }}
    >
      <Box
        onClick={(e) => {
          setShowTooltip(!showTooltip);
          e.preventDefault();
        }}
        className="border-info border text-xs text-info h-3.5 text-center"
        style={{ lineHeight: '0.875rem' }}
      >
        {t('freeitem')}
      </Box>
    </Tooltip>
  );
}
