import {
  compareResultsSelector,
  mutateCompare,
} from '@/store/slices/compareSlice';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBoxCompare from '../common/CheckBoxCompare';

interface Props {
  sku: string;
  pimImage?: string;
  slugname: string;
  size?: number;
  urlimg?: string;
  isNotText?: boolean;
}

export default function CompareProduct({
  sku,
  pimImage,
  slugname,
  size,
  urlimg,
  isNotText,
}: Props) {
  const compareResults = useSelector(compareResultsSelector);
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const compareClick = (e: any) => {
    e.preventDefault();
    dispatch(
      mutateCompare({
        sku,
        pimImage,
        slugname,
        urlimg,
      }),
    );
  };
  return (
    <div onClick={compareClick} className="cursor-pointer">
      <div className="flex items-center">
        <div>
          <CheckBoxCompare
            value={compareResults.find((item) => item.sku === sku) != null}
            size={size}
          />
        </div>
        <label
          htmlFor={`compare-${sku}`}
          className="cursor-pointer ms-1 text-grayDark line-clamp-1"
          style={{
            fontSize: size ? `${size}rem` : '0.75rem',
            lineHeight: size
              ? `${(size ?? 0.75) + (size ?? 0.75) / 2}rem`
              : '1rem',
            height: size ? `${(size ?? 0.75) + (size ?? 0.75) / 2}rem` : '1rem',
          }}
        >
          {!isNotText && t('compare')}
        </label>
      </div>
    </div>
  );
}
