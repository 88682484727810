import React from 'react';
import { useRouter } from 'next/router';

interface Props {
  creditcardontop_text?: string;
  creditcardontop_link?: string;
}
export default function CreditCardOnTopBadge({
  creditcardontop_text,
  creditcardontop_link,
}: Props) {
  const router = useRouter();

  return creditcardontop_text && creditcardontop_text !== '' ? (
    creditcardontop_link && creditcardontop_link !== '' ? (
      <div
        onClick={(e) => {
          e.preventDefault();
          router.push(`${creditcardontop_link}`);
        }}
        className="border-info border text-xs text-info h-3.5 text-center text-ellipsis overflow-hidden px-2"
        style={{ lineHeight: '0.875rem' }}
      >
        <span>{creditcardontop_text}</span>
      </div>
    ) : (
      <div
        className="border-info border text-xs text-info h-3.5 text-center text-ellipsis overflow-hidden px-2"
        style={{ lineHeight: '0.875rem' }}
      >
        <span>{creditcardontop_text}</span>
      </div>
    )
  ) : (
    <>{creditcardontop_text}</>
  );
}
